import { Client, ClientReservation } from "../../../client.model"
import { ShoppingBasketPackage } from "../../../ShoppingBasketPackage"

export class BookingsRequest {
  bookProcessId: string
  paxes: PackagePax[]
  insurance?: any
  urlDetail?: string
  consents?: { crm: any; publi: any }
  destination?: string
  origin?: string
}
  /*
  static build(
    shoppingBasket: ShoppingBasketPackage,
    client: Client
  ): BookingsRequest {
    const { requestToken, providerToken, paxes, agencyInfo } = shoppingBasket
    const packageClient = new PackageClient()
    packageClient.id = client.id
    packageClient.address = client.address
    packageClient.agency = client.agency
    packageClient.agencyChain = client.agency_chain
    packageClient.birthdate = client.birthDate
    packageClient.city = client.city
    packageClient.createdAt = client.createdAt
    packageClient.document = client.document
    packageClient.email = client.email
    packageClient.name = client.name
    packageClient.nationality = client.nationality
    packageClient.phoneNumber = client.phoneNumber
    packageClient.surname = client.surname
    packageClient.updatedAt = client.updatedAt
    packageClient.bookingData = shoppingBasket

    return {
      requestToken,
      providerToken,
      paxes,
      packageClient,
      agencyInfo,
    }
  } 
  static buildReservation(
    shoppingBasket: ShoppingBasketPackage,
    client: ClientReservation,
    origin: string,
    destination: string,
    preBookingData: string
  ): BookingsRequest {
    const { requestToken, providerToken, paxes, agencyInfo } = shoppingBasket
    const client2 = paxes[0]
    const packageClient = new PackageClientReservation()
    packageClient.birthdate = client2.birthdate
    packageClient.document = client2.documentNumber
    packageClient.email = client2.email
    packageClient.name = client2.name
    packageClient.phoneNumber = client2.phone
    packageClient.surname = client2.lastname
    packageClient.surname2 = client2.lastname2
    packageClient.bookingData = shoppingBasket
    packageClient.origin = origin
    packageClient.destination = destination
    packageClient.preBookingData = preBookingData

    return {
      requestToken,
      providerToken,
      paxes,
      packageClient,
      agencyInfo,
    }
  }
}
*/
export class PackagePax {
  type: string
  name: string
  lastname: string
  lastname2?: string
  abbreviation: string
  birthdate: string
  phoneNumberCode: number
  phone: string
  email: string
  code: string
  ages: number
  documentType: string
  documentNumber: string
  documentExpirationDate: string
  nationality: string
}

export enum PackagePaxEnum {
  "adults" = "Adult",
  "children" = "Child",
  "infants" = "Infant",
}

export class PackageClient {
  id: number
  address?: string
  agency: number
  agencyChain: number
  birthdate: string
  city: string
  createdAt: string
  document: string
  email: string
  name: string
  nationality: string
  phoneNumber: string
  surname: string
  updatedAt: string
  bookingData?: any
}
export class PackageClientReservation {
  id: number
  address?: string
  agency: number
  agencyChain: number
  birthdate: string
  city: string
  createdAt: string
  document: string
  email: string
  name: string
  nationality: string
  phoneNumber: string
  surname: string
  surname2?: string
  updatedAt: string
  bookingData?: any
  origin?: string
  destination?: string
  crmNo: string
  preBookingData: string
}
export class PackageDistribution {
  adults: number
  children: number
  infants: number
}
