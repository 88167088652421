import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms"
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router"
import { filter, map, takeUntil } from "rxjs/operators"
import { Subject } from "rxjs"
import { Location } from "@angular/common"
import { HttpClient } from "@angular/common/http"
import { BookEditComponent } from "../../book-edit.component"
import { TranslateService } from "@ngx-translate/core"
import { TecnoturisService } from "../../../tecnoturis.service"
import saveAs from "file-saver"
import {
  Client,
  BookingsRequest,
  PackagePax,
  ShoppingBasketPackage,
  PackagePaxEnum,
} from "../../../lib-shared/interfaces/index"
import {
  AuthenticationService,
  BasketManager,
  ClientServices,
  NotificationPopupService,
  PackagesProviderService,
  SpinnerLoaderService,
} from "../../../lib-shared/services/index"
import { AgencyChangeService } from "../../../agency-change/index"
import { ROLES } from "../../../const"
import { CustomValidators } from "../../../lib-shared/validators/customValidators"
import * as moment_ from "moment"
import { COUNTRIES } from "../../../../mock/countries"
import { t } from "typy"
import { abbreviations, documentTypes } from "../../../../mock/flights"
import { InsuranceService } from "../../../lib-shared/services/insurance.service"

const moment = moment_
@Component({
  selector: "lib-multidestination-booking-form",
  templateUrl: "./multidestination-booking-form.component.html",
  styleUrls: ["./multidestination-booking-form.component.scss"],
  providers: [
    PackagesProviderService,
    ClientServices,
    { provide: "user-host", useValue: "user" },
  ],
})
export class MultidestinationBookingForm
  extends BookEditComponent
  implements OnInit, OnDestroy
{
  @Input() onlyResumeInfo = false
  @Input() origin = ""
  @Input() destination = ""
  shoppingBasket: ShoppingBasketPackage
  form: FormGroup
  forms: FormGroup
  ltsForm: FormGroup
  bookingRequest: BookingsRequest = new BookingsRequest()
  abbreviations = abbreviations
  documentTypes = documentTypes
  countries = COUNTRIES
  newClient: boolean
  client: Client
  waivedInsurance = false
  acceptedConditions = false
  auth = AuthenticationService.getUser()
  total = 0
  maxBirthdateForAdults = moment().subtract(25, "years").toDate()
  maxBirthdateForChildren = moment().subtract(2, "years").toDate()
  maxBirthdateForInfants = moment().toDate()
  minDocumentExpirationDate = moment().add(1, "years").toDate()
  roles = ROLES
  p = 1
  infoAgenciaToken = {}
  midInsurance = []
  selectedInsurances: any[] = []
  selectedInsurancesId: any[] = []
  urlDetail: string
  urlContrato: string
  totalInsurances: number;
  private midInsuranceToken: string
  prebookingData?: string

  get adultsFormArray(): FormArray {
    return this.forms.controls["adults"] as FormArray
  }
  get childrenFormArray(): FormArray {
    return this.forms.controls["children"] as FormArray
  }
  get infantsFormArray(): FormArray {
    return this.form.controls["infants"] as FormArray
  }

  private unsubscribe$ = new Subject()
  checkboxForm: FormGroup
  constructor(
    private activatedRoute: ActivatedRoute,
    public packageService: PackagesProviderService,
    private formBuilder: FormBuilder,
    private router: Router,
    private loaderService: SpinnerLoaderService,
    protected location: Location,
    protected translate: TranslateService,
    protected notification: NotificationPopupService,
    protected http: HttpClient,
    public _basketManager: BasketManager,
    protected clientServices: ClientServices,
    private route: ActivatedRoute,
    protected agencyChangeService: AgencyChangeService,
    public tecnoturisService: TecnoturisService,
    private insuranceService: InsuranceService,
    private fb: FormBuilder
  ) {
    super(
      location,
      http,
      clientServices,
      notification,
      translate,
      tecnoturisService,
      agencyChangeService
    )
    this.checkboxForm = this.fb.group({
      firstCheckbox: [false],
      secondCheckbox: [false],
      thirdCheckbox: [false, Validators.requiredTrue],
    })
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        map(() => window.history.state),
        takeUntil(this._unsubscribe)
      )
      .subscribe(async (state) => {
        this.midInsuranceToken =
          this.activatedRoute.snapshot.queryParams.midInsuranceToken
        if (state.package) {
          this.urlDetail = state.urlDetail
          this.shoppingBasket = ShoppingBasketPackage.initialize(state.package)
          this.total = this.shoppingBasket.totalAmount
          this.urlContrato = `${window.location.protocol}//${window.location.hostname}/assets/files/pdf/${this.shoppingBasket.providerName.toLowerCase()}.pdf`;
          this.prebookingData = this.shoppingBasket.preBookingData || null
          this.initializeFormV2()
          this.initializeClient()
        } else {
          const uuid = this.activatedRoute.snapshot.params["id"]
          const itemsFromStorage = await this._basketManager.getList()
          this.shoppingBasket = itemsFromStorage.find(
            (item) => item.uuid === uuid
          )
          if (this.shoppingBasket) {
            this.total = this.shoppingBasket.totalAmount
            this.initializeForm()
            this.initializeClient()
          }
        }
      })
    this.insuranceService
      .getInsurance(this.midInsuranceToken)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (data !== null && data !== undefined) {
          const { policies } = data;
          if (policies) {
            this.midInsurance = policies;
          }
        }
      })
  }

  setInsurance(insuranceId: any): void {
    // check if this insurance was added
    const exist = this.selectedInsurances.find(
      (insurance) => insurance.cdInsurance === insuranceId
    )
    if (exist) {
      this.selectedInsurances = this.selectedInsurances.filter(
        (insurance) => insurance.cdInsurance !== insuranceId
      )
      this.selectedInsurancesId = this.selectedInsurancesId.filter(
        (insurance) => insurance !== insuranceId
      )
      this.recalculateTotal()
      return
    }

    const selectedInsurance = this.midInsurance.find(
      (insurance) => insurance.cdInsurance === insuranceId
    )

    this.selectedInsurancesId.push(insuranceId)
    this.selectedInsurances.push(selectedInsurance)
    this.recalculateTotal()
  }

  recalculateTotal(): void {
    this.total = this.shoppingBasket.totalAmount
    // add insurance price based on paxes
    const totalPaxes =
      this.shoppingBasket.adults +
      this.shoppingBasket.children +
      this.shoppingBasket.infants
    this.selectedInsurances.forEach((insurance) => {
      this.total += insurance.price * totalPaxes
    })
    this.totalInsurances = this.total - this.shoppingBasket.totalAmount;
  }

  getNameTransfer(name, hotel): string {
    return name.replace("Hotel", "Hotel " + hotel.name)
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  redirectToSummary(): string {
    return this.microsite
      ? "/bookings/summary"
      : "/integration/bookings/book-summary"
  }

  downloadInsuranceWaiver(): void {
    if (!this.waivedInsurance) {
      const pdfUrl = "assets/files/pdf/renuncia-al-seguro.pdf"
      const pdfName = "renuncia-al-seguro.pdf"
      saveAs(pdfUrl, pdfName)
    }
  }

  async book(): Promise<void> {
    if (!this.acceptedConditions) {
      this.loaderService.show()
      const adults = this.adultsFormArray.value as PackagePax[]
      const children = this.childrenFormArray.value as PackagePax[]
      this.completeShoppingBasket(adults, children)
      /*const bookingParameters = BookingsRequest.buildReservation(
        this.shoppingBasket,
        this.client,
        this.origin,
        this.destination,
        this.prebookingData
      )
      
      bookingParameters.insurance = this.selectedInsurances
      bookingParameters.urlDetail = this.urlDetail
      bookingParameters.consents = {
        crm: this.checkboxForm.controls.firstCheckbox.value,
        publi: this.checkboxForm.controls.secondCheckbox.value,
      }*/

      const data: BookingsRequest  = {
        insurance: this.selectedInsurances,
        consents: {
          crm: this.checkboxForm.controls.firstCheckbox.value,
          publi: this.checkboxForm.controls.secondCheckbox.value,
        },
        urlDetail: this.urlDetail,
        paxes: this.shoppingBasket.paxes,
        bookProcessId: this.shoppingBasket.bookProcessId,
        origin: this.origin,
        destination: this.destination,
      }

      this.packageService.book(data)
        .then(
          async (res) => {
            const url = res["redirectURL"]
            window.location.href = url
          },
          (err) => {
            this.router.navigate(["/packages/book-confirmation?failed=true"])
            this.loaderService.hide()
          }
        )
    }
  }

  cancel(): void {
    this.location.back()
  }

  async addToBasketAndRedirect(redirectPath?: string): Promise<void> {
    const adults = this.adultsFormArray.value as PackagePax[]
    const children = this.childrenFormArray.value as PackagePax[]
    this.completeShoppingBasket(adults, children)
    await this._basketManager.addOrUpdate(this.shoppingBasket)
    const redirectToPath = !redirectPath ? this.redirectToPath() : redirectPath
    this.router.navigateByUrl(redirectToPath, { replaceUrl: true })
  }

  private initializeForm(): void {
    this.forms = this.formBuilder.group({
      adults: this.formBuilder.array([]),
      children: this.formBuilder.array([]),
    })
    let adults: PackagePax[] = []
    let children: PackagePax[] = []
    let infants: PackagePax[] = []
    if (this.shoppingBasket.paxes) {
      adults = this.shoppingBasket.paxes.filter((pax) => pax.type === "Adult")
      children = this.shoppingBasket.paxes.filter((pax) => pax.type === "Child")
      infants = this.shoppingBasket.paxes.filter((pax) => pax.type === "Infant")
    }
    for (let i = 0; i < this.shoppingBasket.adults; i++) {
      this.initializeFormByPaxType("adults", adults[i])
    }
    for (let i = 0; i < this.shoppingBasket.children; i++) {
      this.initializeFormByPaxType("children", children[i])
    }
  }

  private initializeFormV2(): void {
    this.forms = this.formBuilder.group({
      adults: this.formBuilder.array([]),
      children: this.formBuilder.array([]),
    })

    for (const dist of this.shoppingBasket.distribution) {
      this.initializeFormByPaxTypeV2(dist)
    }
  }

  getFormData(): FormArray {
    return this.forms.get("passengers") as FormArray
  }

  private initializeFormByPaxTypeV2(pax: any): void {
    const adults = new Array(pax.adults).fill(undefined)
    adults.map((adult, i) => {
      ;(this.forms.controls["adults"] as FormArray).push(
        this.formBuilder.group({
          abbreviation: [
            t(pax, "abbreviation").safeString,
            Validators.required,
          ],
          name: [t(pax, "name").safeString, Validators.required],
          code: this.p++,
          ages: 25,
          lastname: [t(pax, "lastname").safeString, Validators.required],
          lastname2: [t(pax, "lastname").safeString],
          phone: [t(pax, "phone").safeString, i === 0 && Validators.required],
          email: [t(pax, "email").safeString, i === 0 && Validators.required],
          postalCode: [
            t(pax, "postalCode").safeString,
            i === 0 && Validators.required,
          ],
          locality: [
            t(pax, "locality").safeString,
            i === 0 && Validators.required,
          ],
          documentType: [
            t(pax, "documentType").safeString || "PAS",
            Validators.required,
          ],
          documentNumber: [
            t(pax, "documentNumber").safeString,
            Validators.required,
          ],
          birthdate: [t(pax, "birthdate").safeString, [Validators.required]],
          nationality: [
            t(pax, "nationality").safeString || "ES",
            Validators.required,
          ],
          phoneNumberCode: [
            t(pax, "phoneNumberCode").safeObject || 34,
            i === 0 && Validators.required,
          ],
          type: "Adult",
        })
      )
    })

    if (pax.children.length > 0) {
      pax.children.map((child) => {
        ;(this.forms.controls["children"] as FormArray).push(
          this.formBuilder.group({
            abbreviation: [
              t(pax, "abbreviation").safeString,
              Validators.required,
            ],
            name: [t(pax, "name").safeString, Validators.required],
            code: this.p++,
            ages: child,
            lastname: [t(pax, "lastname").safeString, Validators.required],
            lastname2: [t(pax, "lastname").safeString],
            phone: [t(pax, "phone").safeString, Validators.nullValidator],
            email: [t(pax, "email").safeString, Validators.nullValidator],
            documentType: [
              t(pax, "documentType").safeString || "PAS",
              Validators.required,
            ],
            documentNumber: [
              t(pax, "documentNumber").safeString,
              Validators.nullValidator,
            ],
            birthdate: [
              t(pax, "birthdate").safeString,
              [
                Validators.required,
                CustomValidators.maxDates(child, this.shoppingBasket.checkIn),
              ],
            ],
            // documentExpirationDate: [
            //   t(pax, "documentExpirationDate").safeString,
            //   [Validators.required],
            // ],
            nationality: [
              t(pax, "nationality").safeString || "ES",
              Validators.required,
            ],
            phoneNumberCode: [
              t(pax, "phoneNumberCode").safeObject || 34,
              Validators.required,
            ],
            type: "Child",
          })
        )
      })
    }
  }

  private initializeFormByPaxType(arrayName: string, pax: PackagePax): void {
    if (arrayName == "children") {
      ;(this.forms.controls[arrayName] as FormArray).push(
        this.formBuilder.group({
          abbreviation: [
            t(pax, "abbreviation").safeString,
            Validators.required,
          ],
          name: [t(pax, "name").safeString, Validators.required],
          code: [t(pax, "code").safeNumber, Validators.required],
          ages: [t(pax, "ages").safeNumber, Validators.required],
          lastname: [t(pax, "lastname").safeString, Validators.required],
          lastname2: [t(pax, "lastname2").safeString, Validators.required],
          phone: [t(pax, "phone").safeString, Validators.nullValidator],
          email: [t(pax, "email").safeString, Validators.nullValidator],
          documentType: [
            t(pax, "documentType").safeString || "PAS",
            Validators.required,
          ],
          documentNumber: [
            t(pax, "documentNumber").safeString,
            Validators.nullValidator,
          ],
          birthdate: [
            t(pax, "birthdate").safeString,
            [
              Validators.required,
              CustomValidators.maxDates(pax.ages, this.shoppingBasket.checkIn),
            ],
          ],
          // documentExpirationDate: [
          //   t(pax, "documentExpirationDate").safeString,
          //   [Validators.required],
          // ],
          nationality: [
            t(pax, "nationality").safeString || "ES",
            Validators.required,
          ],
          phoneNumberCode: [
            t(pax, "phoneNumberCode").safeObject || 34,
            Validators.required,
          ],
          type: [PackagePaxEnum[arrayName]],
        })
      )
    } else {
      ;(this.forms.controls[arrayName] as FormArray).push(
        this.formBuilder.group({
          abbreviation: [
            t(pax, "abbreviation").safeString,
            Validators.required,
          ],
          name: [t(pax, "name").safeString, Validators.required],
          code: [t(pax, "code").safeNumber, Validators.required],
          ages: [t(pax, "ages").safeNumber, Validators.required],
          lastname: [t(pax, "lastname").safeString, Validators.required],
          lastname2: [t(pax, "lastname2").safeString, Validators.required],
          phone: [t(pax, "phone").safeString, Validators.nullValidator],
          email: [t(pax, "email").safeString, Validators.nullValidator],
          documentType: [
            t(pax, "documentType").safeString || "PAS",
            Validators.required,
          ],
          documentNumber: [
            t(pax, "documentNumber").safeString,
            Validators.nullValidator,
          ],
          birthdate: [t(pax, "birthdate").safeString, [Validators.required]],
          // documentExpirationDate: [
          //   t(pax, "documentExpirationDate").safeString,
          //   [Validators.required],
          // ],
          nationality: [
            t(pax, "nationality").safeString || "ES",
            Validators.required,
          ],
          phoneNumberCode: [
            t(pax, "phoneNumberCode").safeObject || 34,
            Validators.required,
          ],
          type: [PackagePaxEnum[arrayName]],
        })
      )
    }
  }

  private initializeClient(): void {
    this.clientServices.subscription
      .pipe(
        filter(
          (client) =>
            client && !(Object.keys(client).length === 0) && client.isPax
        )
      )
      .subscribe((client: Client) => {
        if (client && !(Object.keys(client).length === 0)) {
          const paxesControl = this.forms.controls["adults"] as FormArray
          paxesControl.at(0).get("name").setValue(client.name)
          paxesControl.at(0).get("lastname").setValue(client.surname)
          paxesControl.at(0).get("email").setValue(client.email)
          paxesControl.at(0).get("documentNumber").setValue(client.document)
          paxesControl.at(0).get("phone").setValue(client.phoneNumber)
          paxesControl.at(0).get("type").setValue("Adults")
        }
      })
  }

  private completeShoppingBasket(
    adults: PackagePax[],
    children: PackagePax[]
  ): void {
    this.shoppingBasket.paxes = [...adults, ...children]
  }
  onFirstCheckboxChange(event: any) {
    if (!event.checked && this.checkboxForm.controls.secondCheckbox.value) {
      this.checkboxForm.controls.secondCheckbox.setValue(false)
    }
  }

  private navigateToConfirmationPage(
    shoppingBasket: ShoppingBasketPackage,
    bookingPostResDto: any,
    failed: boolean
  ): void {
    const navigationExtras: NavigationExtras = {}
    if (failed) {
      navigationExtras.state = {
        bookingPostResDto: [{ failed: true }],
      }
    } else {
      navigationExtras.state = {
        bookingPostResDto: [bookingPostResDto],
        shoppingBasket,
      }
    }
    this.router.navigate(["/packages/book-confirmation"], navigationExtras)
  }

  showDocument(url: string){
    window.open(url);
  }
}
