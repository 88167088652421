import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VeciService {

    constructor(
        private http: HttpClient
    ) {

    }

    getHtml<T>(): Observable<any>{
        const url = `${environment.microsites}/api/v1/common/html/`
        return this.http.get<any>(url)
    }
    getRGPD<T>(): Observable<any>{
        const url = `${environment.microsites}/api/v1/common/rgpd/data/`
        return this.http.get<any>(url)
    }
}
